@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.transition-300ms {
  @apply transition-all duration-300 ease-linear;
}

.links-desktop a {
  @apply text-black;
}

.regular-link {
  @apply block text-lg text-white mx-5 border-b-2 border-transparent hover:border-primary transition-300ms;
}

.link-active {
  @apply border-b-2 border-primary font-semibold;
}

.hamburger {
  @apply w-6 aspect-square lg:hidden flex flex-col justify-evenly items-center;
}

.hamburger span {
  @apply block bg-black w-full h-[2px];
}

.cart-mobile {
  @apply block lg:hidden fixed bottom-10 right-0 w-screen;
}

.button {
  @apply py-3 px-5 m-3 lg:my-5 rounded-3xl cursor-pointer font-semibold;
}

.button.red {
  @apply border-2 border-red-600 hover:text-white hover:bg-red-600 active:text-white active:bg-red-600 transition-300ms;
}

.button.green {
  @apply border-2 border-green-600 hover:text-white hover:bg-green-600 active:text-white active:bg-green-600 transition-300ms;
}

.button.main {
  @apply mx-0 rounded-2xl rounded-bl-none rounded-tr-none bg-primary text-black;
}

.categories-parent::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(9, 9, 121, 0) 0%,
    rgba(8, 42, 143, 0) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 10;
}

.categories input {
  @apply invisible;
}

.categories label {
  @apply opacity-75 cursor-pointer py-1;
}

.categories input:checked + label {
  @apply opacity-100 rounded-full font-bold bg-primary px-3;
}

.categories button {
  @apply opacity-75;
}

.categories button.active {
  @apply opacity-100 rounded-full font-bold bg-primary w-10 aspect-square;
}

.input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-amount {
  -moz-appearance: textfield;
}

.order-sum-input-group {
  @apply text-sm flex justify-between my-3;
}

.order-sum-input-group input {
  @apply border-[1px] border-neutral-300 rounded-md w-[65%];
}
